<template>
  <div class="Sidebar">
    <p id="titleMenu" v-if="this.role == 'cashier' ||this.role == 'owner' ||this.role == 'accountant'">Transaksi</p>
    <router-link to="/voucer" v-if=" this.role == 'cashier' ||
        this.role == 'accountant' || this.role == 'owner' ">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/cjieiyzp.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Voucer</p>
      </div>
    </router-link>
    <router-link to="/orderpayment" v-if=" this.role == 'payment' ||
        this.role == 'accountant' || this.role == 'owner' ">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/cjieiyzp.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Pembayaran</p>
      </div>
    </router-link>
    <router-link to="/debt" v-if=" this.role == 'cashier' || this.role == 'owner' || this.role == 'accountant' " >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/puvaffet.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Mutasi Piutang</p>
      </div>
    </router-link>
    <router-link to="/debtbalance" v-if=" this.role == 'cashier' || this.role == 'owner' || this.role == 'accountant' " >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/wxnxiano.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Saldo Piutang</p>
      </div>
    </router-link>
    <router-link to="/debtvendor" v-if=" this.role == 'cashier' || this.role == 'owner' || this.role == 'accountant' ">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Vendor</p>
      </div>
    </router-link>

    <!-- HUtang -->
    <p id="titleMenu" v-if="this.role == 'adminTeknik' ||this.role == 'owner' ||this.role == 'accountant'">Hutang</p>
    <router-link to="/hutang" v-if="  this.role == 'adminTeknik' || this.role == 'owner'|| this.role == 'hutang'  || this.role == 'accountant' || this.role == 'admin' " >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/puvaffet.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Mutasi Hutang</p>
      </div>
    </router-link>
    <router-link to="/hutangbalance" v-if="this.role == 'adminTeknik' || this.role == 'owner' || this.role == 'accountant'|| this.role == 'hutang' || this.role == 'admin'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/wxnxiano.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Saldo Hutang</p>
      </div>
    </router-link>
    <router-link to="/hutangvendor" v-if=" this.role == 'adminTeknik' || this.role == 'owner'|| this.role == 'hutang' || this.role == 'accountant' || this.role == 'admin' ">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Vendor Hutang</p>
      </div>
    </router-link>

    <!-- Finance  -->
    <p id="titleMenu" v-if="this.role == 'accountant' || this.role == 'owner'"> Keuangan </p>
    <router-link to="/jurnal" v-if="this.role == 'accountant' || this.role == 'owner'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/nocovwne.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Jurnal</p>
      </div>
    </router-link>
    <router-link
      to="/labarugi"
      v-if="this.role == 'accountant' || this.role == 'owner'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/rgyftmhc.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Laba Rugi</p>
      </div>
    </router-link>
    <router-link
      to="/neraca"
      v-if="this.role == 'accountant' || this.role == 'owner'"
    >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/wfkxiwtw.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Neraca</p>
      </div>
    </router-link>
    <router-link
      to="/bukubesar"
      v-if="this.role == 'accountant' || this.role == 'owner'"
    >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/wxnxiano.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Buku Besar</p>
      </div>
    </router-link>
    <router-link
      to="/subma"
      v-if="this.role == 'accountant' || this.role == 'owner'"
    >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/huwchbks.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Sub Mata Anggaran</p>
      </div>
    </router-link>

    <!-- Penagihan -->
    <p
      id="titleMenu"
      v-if="this.role == 'Account Receivable' || this.role == 'owner'"
    >
      Penagihan
    </p>
    <router-link
      to="/receivable"
      v-if="this.role == 'Account Receivable' || this.role == 'owner'"
    >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/puvaffet.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Tagihan</p>
      </div>
    </router-link>
    <router-link
      to="/receivablebalancee"
      v-if="this.role == 'Account Receivable' || this.role == 'owner'"
    >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/wxnxiano.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Saldo Tagihan</p>
      </div>
    </router-link>
    <router-link
      to="/receivablevendor"
      v-if="this.role == 'Account Receivable' || this.role == 'owner'"
    >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Vendor</p>
      </div>
    </router-link>

    <!-- Solar -->
    <p id="titleMenu" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'solar'">Solar</p>
    <router-link to="/solar" v-if=" this.role == 'accountant' || this.role == 'owner' ||this.role == 'solar'">
      <div class="listMenu">
        <img src="../assets/fuel.png" alt="" />
        <p class="sidebarMenu">Penggunaan Solar</p>
      </div>
    </router-link>
    <router-link to="/rekapsolar" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'solar'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/puvaffet.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Rekap Solar</p>
      </div>
    </router-link>
    <router-link to="/solarbalance" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'solar'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/puvaffet.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Saldo Solar</p>
      </div>
    </router-link>
    <p id="titleMenu" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'solar'">Dana Gantung Solar</p>
    <router-link to="/dgsolar" v-if=" this.role == 'solar' || this.role == 'owner' || this.role == 'accountant' " >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/puvaffet.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Mutasi</p>
      </div>
    </router-link>
    <router-link to="/solardgbalance" v-if=" this.role == 'solar' || this.role == 'owner' || this.role == 'accountant' " >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/wxnxiano.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Saldo Dana Gantung</p>
      </div>
    </router-link>
    <router-link to="/driver" v-if=" this.role == 'solar' || this.role == 'owner' || this.role == 'accountant' ">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Driver</p>
      </div>
    </router-link>

    <!-- Retase -->
    <p id="titleMenu" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'solar'">Retase</p>
    <router-link to="/retase" v-if=" this.role == 'accountant'|| this.role == 'retase' || this.role == 'owner' ||this.role == 'solar'" >
      <div class="listMenu">
        <img src="../assets/fuel.png" alt="" />
        <p class="sidebarMenu">Retase</p>
      </div>
    </router-link>
    <router-link to="/fare" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'retase'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/puvaffet.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Daftar Tarif</p>
      </div>
    </router-link>
    
    <!-- Material -->
    <p id="titleMenu" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'material' || this.role == 'gudang'
      "
    >
      Material
    </p>
    <router-link to="/material" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||this.role == 'gudang'">
      <div class="listMenu">
        <img src="../assets/brick.png" alt="" />
        <p class="sidebarMenu">Material</p>
      </div>
    </router-link>
    <router-link to="/materialbalance" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||this.role == 'gudang'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Saldo Material</p>
      </div>
    </router-link>
    <router-link
      to="/materialtype"
      v-if="
        this.role == 'accountant' || this.role == 'owner' || this.role == 'material' ">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Jenis Material</p>
      </div>
    </router-link>

    <!-- Produksi -->
    <p
      id="titleMenu"
      v-if="
        this.role == 'accountant' ||
        this.role == 'owner' ||
        this.role == 'material' || 
        this.role == 'gudang'
      "
    >
      Produksi
    </p>
    <router-link to="/productmuatation" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||  this.role == 'gudang'">
      <div class="listMenu">
        <img src="../assets/package.png" alt="" />
        <p class="sidebarMenu">Mutasi Produk</p>
      </div>
    </router-link>
    <router-link to="/productbalance" v-if="this.role == 'accountant' ||this.role == 'owner' ||this.role == 'material' ||  this.role == 'gudang'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/aqajnirr.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Saldo Produk</p>
      </div>
    </router-link>
    <router-link
      to="/producttype"
      v-if="
        this.role == 'accountant' ||
        this.role == 'owner' ||
        this.role == 'material' ||  this.role == 'adminTeknik'
      "
    >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/aqajnirr.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Jenis Produk</p>
      </div>
    </router-link>

    <!-- Sparepart -->
    <p id="titleMenu" v-if=" this.role == 'accountant' ||this.role == 'owner' || this.role == 'sparepart'">Sparepart</p>
    <router-link to="/poorder" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'sparepart'">
      <div class="listMenu">
        <img src="../assets/document.png" alt="" />
        <p class="sidebarMenu">Pencatatan PO</p>
      </div>
    </router-link>
    <router-link to="/sparepartmutation" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'sparepart'">
      <div class="listMenu">
        <img src="../assets/tool.png" alt="" />
        <p class="sidebarMenu">Mutasi Sparepart</p>
      </div>
    </router-link>
    <router-link to="/subbill" v-if=" this.role == 'accountant' || this.role == 'owner' ||  this.role == 'sparepart' ">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/aqajnirr.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">History Sparepart</p>
      </div>
    </router-link>
    <router-link to="/sparepartbalance" v-if=" this.role == 'sparepart' || this.role == 'owner' " >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/aqajnirr.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Saldo Sparepart</p>
      </div>
    </router-link>
    <router-link to="/spareparttype" v-if="this.role == 'owner' ||this.role == 'sparepart' || this.role == 'material' ||  this.role == 'adminTeknik'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/aqajnirr.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Jenis Sparepart</p>
      </div>
    </router-link>

    <!-- Sparepart -->
    <p id="titleMenu" v-if=" this.role == 'accountant' ||this.role == 'owner' || this.role == 'apd'">APD</p>
    <router-link to="/apdmutation" v-if=" this.role == 'accountant' || this.role == 'owner' || this.role == 'apd'">
      <div class="listMenu">
        <img src="../assets/safety.png" alt="" />
        <p class="sidebarMenu">Mutasi APD</p>
      </div>
    </router-link>
    <router-link to="/apdSubBill" v-if=" this.role == 'accountant' || this.role == 'owner' ||  this.role == 'apd' ">
      <div class="listMenu">
        <img src="../assets/document.png" alt="" />
        <p class="sidebarMenu">History APD</p>
      </div>
    </router-link>
    <router-link to="/apdbalance" v-if=" this.role == 'apd' || this.role == 'owner' " >
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/aqajnirr.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Saldo APD</p>
      </div>
    </router-link>
    <router-link to="/apdtype" v-if="this.role == 'owner' ||this.role == 'apd' || this.role == 'material'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/aqajnirr.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Jenis APD</p>
      </div>
    </router-link>

    <!-- Truck -->
    <p id="titleMenu" v-if="this.role == 'owner'||  this.role == 'transport'  ">
      Kendaraan
    </p>
    <router-link to="/transport" v-if="this.role == 'owner' ||  this.role == 'transport' ">
      <div class="listMenu">
        <img src="../assets/transport.png" alt="" />
        <p class="sidebarMenu">Daftar Kendaraan</p>
      </div>
    </router-link>
    <router-link to="/shippungdocument" v-if="this.role == 'owner' ||  this.role == 'transport' ">
      <div class="listMenu">
        <img src="../assets/document.png" alt="" />
        <p class="sidebarMenu">Surat Jalan</p>
      </div>
    </router-link>


    <!-- Inventory -->
    <p id="titleMenu" v-if="this.role == 'material' || this.role == 'accountant' ||this.role == 'owner' ||this.role == 'inventory'">Inventory</p>
    <router-link
      to="/inventory"
      v-if="
        this.role == 'owner' ||  this.role == 'material' ||
        this.role == 'inventory'
      "
    >
      <div class="listMenu">
        <img src="../assets/inventory.png" alt="" />
        <p class="sidebarMenu">Inventory</p>
      </div>
    </router-link>
    <router-link to="/Inventorymutation" v-if="this.role == 'owner' ||  this.role == 'adminTeknik' ||this.role == 'material' || this.role == 'inventory'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/aqajnirr.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Inventory Mutasi</p>
      </div>
    </router-link>

    <p id="titleMenu" v-if="this.role == 'owner' ||  this.role == 'retase'" >Mobile</p>
    <router-link to="/mobileview" v-if="this.role == 'owner'||  this.role == 'retase'">
      <div class="listMenu">
        <img src="../assets/mobile.png" alt="" />
        <p class="sidebarMenu">Mobile</p>
      </div>
    </router-link>

    <p id="titleMenu" v-if="this.role == 'owner'">Database</p>
    <router-link to="/branch" v-if="this.role == 'owner'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/jqnthkou.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Cabang</p>
      </div>
    </router-link>
    <router-link to="/project" v-if="this.role == 'owner'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/qhgmphtg.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">Proyek</p>
      </div>
    </router-link>
    <router-link to="/user" v-if="this.role == 'owner'">
      <div class="listMenu">
        <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover">
        </lord-icon>
        <p class="sidebarMenu">User</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  computed: {
    role() {
      return localStorage.getItem("role");
    },
  },
};
</script>

<style scoped>
.Sidebar {
  padding-left: 5px;
  padding-top: 16px;
  display: flex;
  height: 100%;
  flex-direction: column;
  /* background-color: #ededed; */
  font-weight: 500;
  /* background-color: rgb(120, 39, 39); */
}
img {
  margin: 0 4px;
  max-width: 16px;
}
.sidebarMenu {
  color: black;

  text-decoration: none;
  margin-left: 0;
}
router-link a:hover {
  text-decoration: none;
}
p {
  font-size: 11px;
  margin: 0px;
  align-self: center;
}
#titleMenu {
  font-weight: 800;
  margin-top: 10px;
  align-self: flex-start;
  padding-left: 10px;
}
.Sidebar a:hover {
  text-decoration: none;
}

.Sidebar a:active {
  color: red;
}
.listMenu {
  display: flex;
  margin: 0;
}
lord-icon {
  width: 24px;
  height: 24px;
}

.router-link.active {
  background-color: aqua;
}
</style>
