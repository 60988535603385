<template>
  <div class="mainPage">
    <div class="loginBox">
      <p id="headTitle">SIKU RAJA</p>
      <p id="title">SISTEM KEUANGAN KURNIA JAYA</p>
      <form @submit.prevent="login">
        <p id="inputTitle">username</p>
        <input type="text" required v-model="username">
        <p id="inputTitle">password</p>
        <input type="password" v-model="password" required>
        <button type="submit">LOGIN</button>
        <p id="copyright">God is working things out for you, even though you don't see it.</p>
      </form>
      <div id="snowflakes" class="snowflakes"></div>
    </div>
    <audio id="backgroundMusic" autoplay loop>
      <source :src="musicSourch" type="audio/mpeg">
      Maaf, browser Anda tidak mendukung pemutaran audio.
    </audio>
  </div>
</template>

<script>
import axios from '../API/axios';
import Swal from "sweetalert2";
import moment from 'moment';

export default {
  name: "LoginPage",
  data() {
    return {
      musicSourch:"../assets/haiDunia.mp3",
      username: '',
      password: "",
      number: 0,
      numberFormatted: '0'
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  mounted() {
    this.createSnowflakes();
  },
  methods: {
    async login() {
      try {
        let data = await axios({
          method: 'post',
          url: "/user/login",
          data: {
            username: this.username,
            password: this.password,
          },
        });
        let date = moment(new Date()).format("YYYY-MM-DD");
        console.log(data, "data");
        localStorage.setItem('access_token', data.data.access_token);
        localStorage.setItem('role', data.data.role);
        localStorage.setItem('username', data.data.username);
        localStorage.setItem('date', date);
        Swal.fire("Semangat Pagi", "Semangat Pagi, Semangat Bekerja", "success");
        this.$store.commit("setIsLogin", true);
        this.$store.dispatch('getLocalStorage');
        this.$router.push("/");
      } catch (err) {
        Swal.fire("Maaf", "Password/username anda salah", "error");
      }
    },
    createSnowflakes() {
      const numFlakes = 50;
      const container = document.getElementById('snowflakes');
      const flakes = [];

      for (let i = 0; i < numFlakes; i++) {
        const flake = document.createElement('div');
        flake.className = 'snowflake';
        flakes.push(flake);
        container.appendChild(flake);
      }

      function snowfall() {
        flakes.forEach((flake, i) => {
          const x = Math.random() * window.innerWidth;
          const y = Math.random() * window.innerHeight;
          const size = Math.random() * 3 + 1;

          flake.style.left = x + 'px';
          flake.style.top = y + 'px';
          flake.style.width = size + 'px';
          flake.style.height = size + 'px';

          const animationDuration = Math.random() * 10 + 5 + 's';
          flake.style.animation = `fall ${animationDuration} linear infinite`;

          if (i % 2 === 0) {
            flake.style.animationDelay = '-' + animationDuration / 2;
          }
        });
      }

      snowfall();
    },
  },
};
</script>

<style scoped>
.mainPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url(../assets/christmas2.jpg);
  background-size: contain; /* Mengubah ke contain agar gambar mempertahankan aspeknya */
  background-repeat: repeat; /* Mengulang gambar jika tidak cukup besar */
}

p #marry {
  display:block;
}
.loginBox {
  background-color: #FFFFFF;
  border-radius: 20px;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 32px;
  padding-bottom: 32px;
  width: 400px;
}

#headTitle {
  color: #545454;
  font-size: 28px;
  margin: 0px;
  font-weight: 600;
  text-align: left;
}

#title {
  text-align: left;
  font-size: 16px;
  color: #545454;
  margin: 0px;
  margin-bottom: 10px;
  margin-bottom: 70px;
  font-weight: 500;
}

#inputTitle {
  text-align: left;
  margin: 0;
}

input {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: left;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px #E0E0E0 solid;
}

button {
  margin-top: 10px;
  padding: 10px 0;
  font-size: 18px;
  font-weight: 600;
  color: #ededed;
  border-radius: 20px;
  border: none;
  background-color: #00803C;
}

button:hover {
  color: #00803C;
  background-color: #FFFFFF;
}

form {
  display: flex;
  flex-direction: column;
}

#copyright {
  margin-top: 12px;
  color: #c50101;
  font-weight: 500;
  font-size: 11px;
}

/* Snowflakes (continued) */
.snowflakes {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}




</style>
