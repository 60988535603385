import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from "../views/LoginPage.vue"

// Transaksi
import Voucer from "../views/Voucer.vue"
import OrderPayment from "../views/OrderPayment.vue"
import Jurnal from "../views/Jurnal.vue"
import ShowJurnal from "../views/ShowJurnal.vue"
import Debt from "../views/Debt.vue"
import DebtBalance from "../views/DebtBalance.vue"
import DebtVendor from "../views/DebtVendor.vue"

// Hutang
import Hutang from "../views/Hutang.vue"
import HutangBalance from "../views/HutangBalance.vue"
import HutangVendor from "../views/HutangVendor.vue"


// Finance
import AddJurnal from "../views/AddJurnal.vue"
import EditJurnal from "../views/EditJurnal.vue"
import OneJurnal from "../views/OneJurnal.vue"
import BukuBesar from "../views/BukuBesar.vue"
import Neraca from "../views/Neraca.vue"
import LabaRugi from "../views/LabaRugi.vue"
import SubMa from "../views/SubMa.vue"

// Penagihan
import Receivable from "../views/Receivable.vue"
import ReceivableBalance from "../views/ReceivableBalance.vue"
import ReceivableVendor from "../views/ReceivableVendor.vue"

// solar
import Solar from "../views/Solar.vue"
import RekapSolar from "../views/RekapSolar.vue"
import SolarBalance from "../views/SolarBalance.vue"

import SolarDg from "../views/SolarDg.vue"
import SolarDriver from "../views/SolarDriver.vue"
import SolarDgBalance from "../views/SolarDgBalance.vue"

// Retase
import Retase from "../views/Retase.vue"
import Truck from "../views/Truck.vue"
import Fare from "../views/Fare.vue"



// Material
import Material from "../views/Material.vue"
import MaterialType from "../views/MaterialType.vue"
import SaldoMaterial from "../views/SaldoMaterial.vue"

// Product
import ProductType from "../views/ProductType.vue"
import ProductMutation from "../views/ProductMutation.vue"
import ProductBalance from "../views/ProductBalance.vue"

// Sparepart
import SparepartType from "../views/SparepartType.vue"
import SparepartMutation from "../views/SparepartMutation.vue"
import SparepartBalance from "../views/SparepartBalance.vue"
import SubBillMutation from "../views/SubBillMutation.vue"

// Po
import PoOrder from "../views/PoOrder.vue"

// Transport
import Transport from "../views/Transport.vue"
import ShippingDocument from "../views/ShippingDocument.vue"

// Apd
import ApdType from "../views/ApdType.vue"
import ApdMutation from "../views/ApdMutation.vue"
import ApdBalance from "../views/ApdBalance.vue"
import ApdSubBill from "../views/ApdSubBill.vue"

// inventory 
import Inventory from "../views/Inventory.vue"
import InventoryMutation from "../views/InventoryMutation.vue"

// Database
import User from "../views/User.vue"
import Branch from "../views/Branch.vue"
import Project from "../views/Project.vue"

// mobile
import MobileView from "../views/MobileView.vue"


import Test from "../views/Test.vue"


import Swal from "sweetalert2";
Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    name: 'Test',
    component: Test,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { requiresAuth2: true }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/voucer',
    name: 'voucer',
    component: Voucer,
    meta: { requiresAuth: true }
  },
  {
    path: '/orderpayment',
    name: 'OrderPayment',
    component: OrderPayment,
    meta: { requiresAuth: true }
  },
  {
    path: '/debt',
    name: 'debt',
    component: Debt,
    meta: { requiresAuth: true }
  },
  {
    path: '/debtbalance',
    name: 'DebtBalance',
    component: DebtBalance,
    meta: { requiresAuth: true }
  },
  {
    path: '/debtvendor',
    name: 'DebtVendor',
    component: DebtVendor,
    meta: { requiresAuth: true }
  },
  // Hutang
  {
    path: '/hutang',
    name: 'hutang',
    component: Hutang,
    meta: { requiresAuth: true }
  },
  {
    path: '/hutangbalance',
    name: 'HutangBalance',
    component: HutangBalance,
    meta: { requiresAuth: true }
  },
  {
    path: '/hutangvendor',
    name: 'HutangVendor',
    component: HutangVendor,
    meta: { requiresAuth: true }
  },

  // Finance
  {
    path: '/jurnal',
    name: 'Jurnal',
    component: Jurnal,
    meta: { requiresAuth: true }
  },
  {
    path: '/onejurnal',
    name: 'OneJurnal',
    component: OneJurnal,
    meta: { requiresAuth: true }
  },
  {
    path: '/editjurnal',
    name: 'EditJurnal',
    component: EditJurnal,
    meta: { requiresAuth: true }
  },
  {
    path: '/addjurnal',
    name: 'AddJurnal',
    component: AddJurnal,
    meta: { requiresAuth: true }
  },
  {
    path: '/showJurnal',
    name: 'ShowJurnal',
    component: ShowJurnal,
    meta: { requiresAuth: true }
  },
  {
    path: '/bukubesar',
    name: 'BukuBesar',
    component: BukuBesar,
    meta: { requiresAuth: true }
  },
  {
    path: '/neraca',
    name: 'Neraca',
    component: Neraca,
    meta: { requiresAuth: true }
  },
  {
    path: '/labarugi',
    name: 'LabaRugi',
    component: LabaRugi,
    meta: { requiresAuth: true }
  },
  {
    path: '/subma',
    name: 'SubMa',
    component: SubMa,
    meta: { requiresAuth: true }
  },
  // Penagihan
  {
    path: '/receivable',
    name: 'Receivable',
    component: Receivable,
    meta: { requiresAuth: true }
  },
  {
    path: '/receivablebalancee',
    name: 'ReceivableBalance',
    component: ReceivableBalance,
    meta: { requiresAuth: true }
  },
  {
    path: '/receivablevendor',
    name: 'ReceivableVendor',
    component: ReceivableVendor,
    meta: { requiresAuth: true }
  },
  // Solar
  {
    path: '/solar',
    name: 'Solar',
    component: Solar,
    meta: { requiresAuth: true }
  },

  {
    path: '/rekapsolar',
    name: 'RekapSolar',
    component: RekapSolar,
    meta: { requiresAuth: true }
  },
  {
    path: '/solarbalance',
    name: 'SolarBalance',
    component: SolarBalance,
    meta: { requiresAuth: true }
  },
  {
    path: '/solardgbalance',
    name: 'SolarDgBalance',
    component: SolarDgBalance,
    meta: { requiresAuth: true }
  },
  {
    path: '/dgsolar',
    name: 'SolarDg',
    component: SolarDg,
    meta: { requiresAuth: true }
  },
  {
    path: '/driver',
    name: 'SolarDriver',
    component: SolarDriver,
    meta: { requiresAuth: true }
  },

    // Retase
    {
      path: '/retase',
      name: 'Retase',
      component: Retase,
      meta: { requiresAuth: true }
    },
    {
      path: '/truck',
      name: 'Truck',
      component: Truck,
      meta: { requiresAuth: true }
    },
    {
      path: '/fare',
      name: 'Fare',
      component: Fare,
      meta: { requiresAuth: true }
    },

  // material
  {
    path: '/material',
    name: 'Material',
    component: Material,
    meta: { requiresAuth: true }
  },
  {
    path: '/materialbalance',
    name: 'SaldoMaterial',
    component: SaldoMaterial,
    meta: { requiresAuth: true }
  },
  {
    path: '/materialtype',
    name: 'MaterialType',
    component: MaterialType,
    meta: { requiresAuth: true }
  },
  // Product
  {
    path: '/productmuatation',
    name: 'ProductMutation',
    component: ProductMutation,
    meta: { requiresAuth: true }
  },
  {
    path: '/producttype',
    name: 'ProductType',
    component: ProductType,
    meta: { requiresAuth: true }
  },
  {
    path: '/productbalance',
    name: 'ProductBalance',
    component: ProductBalance,
    meta: { requiresAuth: true }
  },
  // SParepart 
  {
    path: '/spareparttype',
    name: 'SparepartType',
    component: SparepartType,
    meta: { requiresAuth: true }
  },
  {
    path: '/sparepartmutation',
    name: 'SparepartMutation',
    component: SparepartMutation,
    meta: { requiresAuth: true }
  },
  {
    path: '/sparepartbalance',
    name: 'SparepartBalance',
    component: SparepartBalance,
    meta: { requiresAuth: true }
  },
  {
    path: '/subbill',
    name: 'SubBillMutation',
    component: SubBillMutation,
    meta: { requiresAuth: true }
  },

  // Po 
  {
    path: '/poorder',
    name: 'PoOrder',
    component: PoOrder,
    meta: { requiresAuth: true }
  },

  // Transport
  {
    path: '/transport',
    name: 'Transport',
    component: Transport,
    meta: { requiresAuth: true }
  },
  {
    path: '/shippungdocument',
    name: 'ShippingDocument',
    component: ShippingDocument,
    meta: { requiresAuth: true }
  },

  // Apd

  {
    path: '/apdtype',
    name: 'ApdType',
    component: ApdType,
    meta: { requiresAuth: true }
  },
  {
    path: '/apdmutation',
    name: 'ApdMutation',
    component: ApdMutation,
    meta: { requiresAuth: true }
  },
  {
    path: '/apdbalance',
    name: 'ApdBalance',
    component: ApdBalance,
    meta: { requiresAuth: true }
  },
  {
    path: '/apdsubbill',
    name: 'ApdSubBill',
    component: ApdSubBill,
    meta: { requiresAuth: true }
  },
  // inventory
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory,
    meta: { requiresAuth: true }
  },
  {
    path: '/Inventorymutation',
    name: 'InventoryMutation',
    component: InventoryMutation,
    meta: { requiresAuth: true }
  },
  // database
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: { requiresAuth: true }
  },
  {
    path: '/branch',
    name: 'Branch',
    component: Branch,
    meta: { requiresAuth: true }
  },
  {
    path: '/project',
    name: 'Project',
    component: Project,
    meta: { requiresAuth: true }
  },

  // mobile
  {
    path: '/mobileview',
    name: 'MobileView',
    component: MobileView,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('access_token')) {
      Swal.fire("Maaf", "Anda Harus Login Terlebih dahulu", "error");
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth2)) {
    if (localStorage.getItem('access_token')) {
      Swal.fire("Semangat Pagi", "Semangat Pagi dan Semangat Bekerja", "success");
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
