import axios from 'axios';


// export default axios.create({
// 	baseURL: 'https://dbsikuraja.otnielpangkung.com/',
// });
export default axios.create({
	baseURL: 'https://testdbsikuraja.otnielpangkung.com/',
});
// export default axios.create({
// 	baseURL: 'http://localhost:3001',
// });

